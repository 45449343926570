/*
 * ============================
 * File: main.js
 * Project: 'Byocs'
 * File Created: Monay, 5th September 2022 9:00:05 am
 * Author:Jorge Quizamanchuro
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

// import VueApexCharts from 'vue-apexcharts';
// mock
import "./fake-db/index.js";

// Vue.component('apexchart', VueApexCharts);

Vue.use(octaviaKit);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
